export const personalJSON = [
    {
        "id": 20,
        "date": "July 2023",
        "title": "Job",
        "area1": "Proses Yazılım",
        "area2": ["Full Stack Developer", "React.js, .NET 6", "Bursa"],
        "img":"",
    },
    {
        "id": 19,
        "date": "March 2023",
        "title": "Job",
        "area1": "a startup company",
        "area2": ["Freelance Frontend Developer", "React, TypeScript, Jira, MUI, Git", "Remote"],
        "img":"",
    },
    {
        "id": 18,
        "date": "March 2023",
        "title": "Job",
        "area1": "Hogarth Worldwide",
        "area2": ["Frontend Developer", "HTML, CSS, JS, SVN", "İstanbul - Hybrid"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1692519184/goktemkirez.com/images/D1ABC2F3-30F2-4746-BA79-2FF0492EF3B9_1_102_o_qcafoe.jpg",
    },
    {
        "id": 17,
        "date": "September 2022",
        "title": "Job",
        "area1": "Harput Holding",
        "area2": ["Frontend Developer", "React.js, .NET 6", "Bursa"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1692518506/goktemkirez.com/images/7B1916B4-BA6E-4F6F-92F5-191CC9A0B7A0_1_105_c_uawn0n.jpg",
    },
    {
        "id": 16,
        "date": "April 2022",
        "title": "Military Obligation",
        "area1": "",
        "area2": ["Ankara"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1692519669/goktemkirez.com/images/askerlik_qlzmxr.jpg",
    },
    {
        "id": 15,
        "date": "December 2021",
        "title": "Job",
        "area1": "Durak Tekstil",
        "area2": ["Software Engineer", "ASP.Net Webforms, React Native", "Bursa"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106138/goktemkirez.com/images/durak_tmh1ht.jpg",
    },
    {
        "id": 14,
        "date": "May 2019",
        "title": "Job",
        "area1": "Temiziş Ofset & Dijital",
        "area2": ["IT Specialist - Industrial Engineer", ".NET Winform, MS SQL", "Eskişehir"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106344/goktemkirez.com/images/temizis_axvoxk.jpg",
    },
    {
        "id": 12,
        "date": "February 2019",
        "title": "Graduated from Anadolu University",
        "area1": "Bachelor Degree of Industrial Engineering",
        "area2": ["ECTS 2.48/4.00"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106711/goktemkirez.com/images/univMezuniyet_gmlipv.png",
    },
    {
        "id": 11,
        "date": "December 2018 - January 2019",
        "title": "Intern",
        "area1": "Şahince Otomotiv",
        "area2": ["Logistics and Planning Department"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106342/goktemkirez.com/images/sahince_qwpvwl.jpg",
    },
    {
        "id": 10,
        "date": "September 2018",
        "title": "Erasmus Intern",
        "area1": "ML Components GmbH",
        "area2": ["Sales Department"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106276/goktemkirez.com/images/erasmus_jpji79.jpg",
    },
    {
        "id": 13,
        "date": "November 2017 - May 2018",
        "title": "Bachelor Graduation Project",
        "area1": "Anadolu University - Engineering Faculty - Industrial Engineering",
        "area2": ["Tracking, Examination And Analyzing Operator Eye Movements With Eye Tracking Device At The Final Quality Control Point Of The Refrigerator Mounting Line"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106478/goktemkirez.com/images/tez_kmczod.jpg",
    },
    {
        "id": 9,
        "date": "October 2017 - July 2018",
        "title": "Elected Student Representative",
        "area1": "Anadolu University - Engineering Faculty - Industrial Engineering",
        "area2": [],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106408/goktemkirez.com/images/temsilci_ufyn9g.jpg",
    },
    {
        "id": 8,
        "date": "August 2017 - September 2017",
        "title": "Intern",
        "area1": "QNB Finansbank",
        "area2": ["Uludağ Branch"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645962590/goktemkirez.com/images/qnb_oxbgz5.jpg",
    },
    {
        "id": 7,
        "date": "July 2017",
        "title": "Intern",
        "area1": "TOFAŞ - Türk Otomobil Fabrikası A.Ş.",
        "area2": ["Production Department"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645962590/goktemkirez.com/images/tofas_npmzlx.jpg",
    },
    {
        "id": 6,
        "date": "October 2014 - June 2015",
        "title": "Endüstri ve Verimlilik Kulübü",
        "area1": "Anadolu University Student Club",
        "area2": ["Active Membership", "9. Engineering and Management Academy"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106285/goktemkirez.com/images/evk_trvojw.jpg",
    },
    {
        "id": 5,
        "date": "September 2012",
        "title": "Started University",
        "area1": "Anadolu University",
        "area2": ["Engineering Faculty - Industrial Engineering"],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106590/goktemkirez.com/images/universite_tp49e7.jpg",
    },
    {
        "id": 4,
        "date": "October 2010",
        "title": "Elected Student Representative",
        "area1": "Bursa Atatürk Anadolu Lisesi",
        "area2": [],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106340/goktemkirez.com/images/liseTemsilcilik_pujfme.jpg",
    },
    {
        "id": 3,
        "date": "September 2008",
        "title": "Started High School",
        "area1": "Bursa Atatürk Anadolu Lisesi",
        "area2": [],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645106291/goktemkirez.com/images/lise_ppqjmb.jpg",
    },
    {
        "id": 2,
        "date": "September 2001",
        "title": "Started Primary School",
        "area1": "Altıparmak Fethi Açançiçek İlköğretim Okulu",
        "area2": [],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645962939/goktemkirez.com/images/ilkokul_dg5rhy.jpg",
    },
    {
        "id": 1,
        "date": "03 September 1994",
        "title": "Birth",
        "area1": "",
        "area2": [],
        "img":"https://res.cloudinary.com/dajbm4ku6/image/upload/v1645107102/goktemkirez.com/images/birth_ntrr0a.jpg",
    },
]